.select-plan-div {
  background: #283593;
  border-radius: 0.525rem;
  padding: 1.125rem;
  color: #fff;
  margin-bottom: 1.5rem;
}

.select-plan-div .publish-btn {
  background: #fff;
  color: #333333;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 197px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  border: 0;
}

.select-plan-div .publish-btn:hover {
  background: #000;
  color: #fff;
}

.select-plan-div .publish-btn:focus {
  outline: 0;
}

.select-plan-div .disabled-publish-btn {
  background: rgb(226, 225, 225);
  color: #b3b3b3;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 197px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  border: 0;
}

@media (max-width: 575px) {
  .select-plan-div .publish-btn,
  .select-plan-div .disabled-publish-btn {
    position: unset;
    top: unset;
    transform: unset;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.payment-methods {
  position: relative;
  background: #ededed;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  width: 245px;
}

.payment-methods img {
  margin-right: 15px;
}

.payment-methods img:nth-child(1) {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.payment-methods .comming-soon {
  position: absolute;
  top: 6px;
  left: 95px;
  font-size: 8px;
  color: #006dee;
}

.payment-methods .pay-dissable {
  position: absolute;
  top: 14px;
  left: 95px;
  display: block;
  color: #828282;
}

/* subscrip plan */
.subscrip-plan {
  max-width: 562px;
  margin: auto;
}

.subscrip-plan .sel-plan {
  float: left;
  width: 263.5px;
  background: #fff;
  margin: 2% 1.5%;
  border-radius: 8px;
  border: 2px solid #448aff;
  padding: 15px;
}

.subscrip-plan .sel-plan:nth-child(3) {
  display: none;
}

.subscrip-plan .sel-plan {
  color: #333333;
  text-align: center;
}

.subscrip-plan .sel-plan .title {
  font-size: 18px;
  line-height: 21px;
  margin: 0px 20px 20px 20px;
}

.subscrip-plan .sel-plan .currency {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}
.subscrip-plan .sel-plan .amt {
  font-weight: 500;
  font-size: 40px;
  line-height: 21px;
}
.subscrip-plan .sel-plan .period {
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
}

.subscrip-plan .sel-plan .price {
  font-weight: 500;
  font-size: 40px;
  line-height: 39px;
  margin-bottom: 20px;
}

.subscrip-plan .sel-plan .main-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  height: 75px;
}

.subscrip-plan .sel-plan .sub-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-top: 30px;
  margin-bottom: 10px;
}

.subscrip-plan .sel-plan .desc {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  height: 15px;
}

.subscrip-plan .sel-plan button,
.subscrip-plan .sel-plan .selected-plan {
  display: block;
  margin: auto;
  width: 80%;
  height: 48px;
  left: 359px;
  top: 520px;
  background: #448aff;
  border-radius: 8px;
  border: 0;
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 47px;
  color: #fff;
}

.subscrip-plan .sel-plan button:focus {
  outline: 0;
}

.subscrip-plan .special-price {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ff6961;
  margin-top: 5px;
  height: 15px;
}

@media (max-width: 575px) {
  .subscrip-plan .sel-plan {
    width: 240px;
  }
}

/* confirm subscription */
.confirm-title {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 25px;
  margin-bottom: 10px;
}

.confirm-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.total-price {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

#card-holder-name::placeholder {
  font-size: 14px;
  color: #828282;
}

#field-wrapper {
  background: #ededed;
  padding: 7px;
}

#field-wrapper input {
  margin-top: 4px;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 5px;
  font-size: 14px;
  background: #ededed;
  height: 40px;
}

#field-wrapper input:focus {
  border: 1px solid #fff !important;
  background: #fff;
  border-radius: 0;
}

#field-wrapper input::placeholder {
  font-size: 14px;
  color: #828282;
}

.sc-ifAKCX {
  display: none;
}

.sub-update-plan .modal-dialog {
  max-width: 420px;
}
