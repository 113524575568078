.sign-up-form {
  font-family: "Roboto", sans-serif;
  background: #fff;
  border-radius: 5px;
  padding: 25px 8%;
}

.sign-up-form .form-row {
  margin-bottom: 10px;
}

.sign-up-form .input-fields {
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 0;
  font-weight: 500;
}

.sign-up-form input,
.sign-up-form select {
  background: #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #ededed;
  color: #333333;
  font-size: 16px;
}

.sign-up-form select:hover {
  cursor: pointer !important;
}

.sign-up-form input:focus,
.sign-up-form select:focus {
  border: 1px solid #151b26 !important;
}

.sign-up-form .info {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.58);
  background: #e3f2fd;
  margin-bottom: 5px;
  padding: 8px;
  cursor: default;
}

.sign-up-form .info .fa-info-circle {
  color: #c3c3c3;
  font-size: 16px;
  vertical-align: bottom;
}

.sign-up-form .fa-exclamation-triangle {
  color: rgb(179, 116, 1);
}

.sign-up-form .input-group-text {
  border: 0;
  background: #ededed;
  color: #828282;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.sign-up-form .invalid-feedback {
  margin-top: 2px;
}

.sign-up-form .register-btn {
  display: block;
  width: 280px;
  height: 36px;
  background: #ff6961;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: auto;
  border: 0;
  margin-top: 30px;
}

@media (max-width: 480px) {
  .sign-up-form .register-btn {
    width: 200px;
  }
}

.sign-up-form .register-btn:focus {
  outline: 0;
}

.sign-up-form .register-btn:hover {
  background: #eb3228;
}

.sign-up-form .agree-policy {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4f4f4f;
  margin-top: 10px;
  margin-bottom: 0;
}

/* continue to dashboard */
.continue-heading {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #4f4f4f;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
}

.logged-details-div {
  background: #ededed;
  border-radius: 6px;
  padding: 15px;
}

.logged-details-div p:nth-child(1) {
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
}

.logged-details-div p:nth-child(2) {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin-bottom: 0;
}

.logged-details-div p:nth-child(3) {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 0;
}

.sign-up-form .not-your-account {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  text-align: center;
  margin-top: 8px;
}

.sign-up-form .not-your-account span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #333333;
  cursor: pointer;
}

.sign-up-form .new {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-top: 40px;
  margin-bottom: 0;
}

.sign-up-form .new span {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2979ff;
  cursor: pointer;
}

.sign-up-form .new span:hover {
  text-decoration: underline;
}
