.color-row {
  width: 400px;
}

.color-col {
  display: inline-block;
  width: 50%;
}

.color-col p {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 1.125rem;
  margin-bottom: 0.5rem;
}

.color-col span {
  font-size: 0.75rem;
  line-height: 0.875rem;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 9px;
  margin-left: 5px;
}

@media (max-width: 400px) {
  .color-col {
    width: 100%;
  }
}

/* image uploads */

.uplaod-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 1.125rem;
  margin-bottom: 0.5rem;
  margin-top: 25px;
  margin-bottom: 5px;
}

.upload-info {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
  margin-bottom: 5px;
}

.upload-img-container {
  position: relative;
  height: 120px;
  width: 120px;
  background: #ededed;
  padding: 0;
  border-radius: 6px;
}

.logo-preview-styling {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-img-btn {
  position: absolute;
  bottom: 10px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  color: #4f4f4f;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 10px;
  margin-bottom: 0;
  cursor: pointer;
  z-index: 22;
  width: 90px;
  left: 15px;
  text-align: center;
}

.upload-img-container .del-uploads,
.cover-img-container .del-uploads {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

#logo-upload,
#cover-upload,
#fav-upload {
  display: none;
}

/* cover img */
.cover-img-container {
  position: relative;
  height: 180px;
  width: 100%;
  background: #ededed;
  padding: 0;
  border-radius: 6px;
}

.cover-img {
  width: 100px;
  display: block;
  margin: auto;
  padding-top: 15px;
}

.cover-preview-styling {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-cover-img-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 184%);
  width: 90px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  color: #4f4f4f;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 10px;
  display: block;
  margin: auto;
  margin-bottom: 0;
  cursor: pointer;
  text-align: center;
}

.cropper-bg {
  width: 100% !important;
  height: 200px !important;
}

.img-preview {
  width: 400px;
  height: 200px;
}

.form-control {
  color: #222;
  background-color: #ededed;
  /* border: none; */
  border-radius: 0.25rem;
}
