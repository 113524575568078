.chips-div div {
  background: #ededed !important;
  border: 0 !important;
}

.chips-div input {
  background: #ededed;
}

.invalid-areas > div {
  border: 1px solid #dc3545 !important;
}

.chips-div div div[data-radium="true"] {
  padding: 7px 5px 7px 13px !important;
  background: #4f4f4f33 !important;
  border-radius: 13px !important;
}

.chips-div div div[data-radium="true"] span {
  background: rgb(236, 236, 236);
  border-radius: 50%;
  padding: 2px 5px 2px 2px;
  margin-left: 7px;
}

.chips-div div div[data-radium="true"] span:hover {
  color: unset !important;
  background: #fff;
}
