/* open hours */
.css-nakgy8-TimeKeeper {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.table-open-hours {
  margin-top: 1.25rem;
  line-height: 3rem;
}

.table-open-hours tr td:nth-child(1) {
  width: 100px;
}

.table-open-hours .time {
  width: 95px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ededed;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.time-dropdown {
  display: inline-block;
  width: 115px;
  cursor: pointer !important;
}

.add-cost-com select:hover {
  cursor: pointer !important;
}

.table-open-hours .time:focus {
  border: 1.5px solid rgb(168, 168, 168) !important;
  outline: 0;
  background: #fff;
}

.btn-success:focus {
  box-shadow: none;
}

.btn-danger:focus {
  box-shadow: none;
}

.table-open-hours .btn-success {
  border-radius: 0.2rem 0 0 0.2rem;
  background: #28a745 !important;
  color: #fff !important;
}

.table-open-hours .btn-danger {
  border-radius: 0 0.2rem 0.2rem 0;
  background: #bd2130 !important;
  color: #fff !important;
}

.table-open-hours .btn-dissable {
  background: #ededed;
  color: #a5a5a5;
}

.time {
  width: 95px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ededed;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.time:focus {
  border: 1.5px solid rgb(168, 168, 168) !important;
  outline: 0;
  background: #fff;
}

.to-time-devider {
  padding-left: 15px;
  padding-right: 15px;
}

.time-remove-btn {
  margin-left: 15px;
  cursor: pointer;
}

@media (min-width: 351px) and (max-width: 480px) {
  .table-open-hours tr td:nth-child(1) {
    width: 85px;
    font-size: 14px;
  }

  .table-open-hours .time {
    width: 70px;
    padding: 0.375rem 0.25rem;
    font-size: 14px;
  }

  .to-time-devider {
    padding-left: 10px;
    padding-right: 10px;
  }

  .time-remove-btn {
    margin-left: 10px;
  }
}

@media (max-width: 350px) {
  .table-open-hours tr td:nth-child(1) {
    width: 75px;
    font-size: 13px;
  }

  .table-open-hours .time {
    width: 65px;
    padding: 0.375rem 0.25rem;
    font-size: 13px;
  }

  .to-time-devider {
    padding-left: 5px;
    padding-right: 5px;
  }

  .time-remove-btn {
    margin-left: 5px;
  }
}
