.view-business {
  font-size: 14px;
  color: #03a9f4;
  text-decoration: none;
  font-weight: 600;
}

.view-business:hover {
  color: #2537d8;
  text-decoration: none;
}

/* category */
.category-name {
  color: #000;
  font-weight: 600;
  font-size: 19px;
  margin: 10px 18px;
}

.add-category {
  font-size: 16px;
  margin: 10px 15px;
  font-weight: 600;
  cursor: pointer;
}

.cancel-cat-btn {
  padding: 0 10px;
  margin-left: 0.5rem;
  background: #d4d4d4;
  color: #4f4f4f;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}

.add-cat-btn {
  background: #448aff;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 500;
  margin-left: 0.5rem;
  width: 100px;
}

@media (max-width: 414px) {
  .add-category-input {
    width: 70%;
  }

  .add-cat-btn {
    width: 18%;
    margin-left: 5px;
    padding: 7px 5px;
  }
}

.edit-item-name {
  font-size: 18px;
  /* font-weight: 500; */
}

.menu-item-card {
  display: block;
  float: left;
  position: relative;
  width: 30%;
  margin: 1.65%;
  border-radius: 5px;
  height: 344px;
  background: #f7f7f7;
}

.menu-item-card .img-div {
  position: relative;
  height: 150px;
  overflow: hidden;
}

.menu-item-card .del-item {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(80, 80, 80);
  cursor: pointer;
  background: #fff;
  padding: 5px 7px;
  border-radius: 50%;
}

.menu-item-card .edit-item {
  display: none;
}

.menu-item-card .del-item:hover {
  color: #fff;
  background: #df0c0c;
}

.menu-item-card .img-div img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.menu-item-card:hover .img-div .edit-item {
  display: block !important;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: 1px solid;
  border-radius: 5px;
  width: 80px;
  text-align: center;
  line-height: 18px;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.menu-item-card:hover .img-div .edit-item:hover {
  color: #000;
  background: #fff;
}

.menu-item-card:hover .img-div img {
  filter: brightness(50%);
}

.menu-item-card .name {
  font-weight: 600;
  margin: 10px;
  height: 38px;
  overflow: hidden;
  font-size: 16px;
}

.menu-item-card .desc {
  margin: 10px;
  font-size: 14px;
  color: #717171;
  height: 65px;
  overflow: hidden;
  text-align: left;
}

.menu-item-card .price {
  font-weight: 900;
  margin-left: 10px;
  padding-bottom: 5px;
  font-size: 15px;
  margin-bottom: 0;
}

.menu-item-card .add-item {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 35%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  text-align: center;
  color: #616161;
}

.isAvalilable-div {
  font-size: 14px;
  margin-right: 10px;
}

.available-yes {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
  padding: 1px 5px;
  border: 0;
  font-weight: 500;
  float: right;
}

.available-no {
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 1px 7px;
  border: 0;
  font-weight: 500;
  float: right;
  color: #fff;
}

.avaliable-empty {
  background: #e6e6e6;
  padding: 1px 5px;
  border: 0;
  font-weight: 400;
  float: right;
  color: #6f6f6f;
}

.avaliable-empty:hover {
  color: #000;
  background: #ccc;
}

.avaliable-empty:focus {
  outline: 0;
  box-shadow: none;
}

.available-yes:focus,
.available-no:focus {
  outline: 0;
  box-shadow: none;
}

@media (max-width: 480px) {
  .menu-item-card {
    display: block;
    width: 92%;
    height: 335px;
    margin: 5px 4%;
  }
}

@media (min-width: 481px) and (max-width: 650px) {
  .menu-item-card {
    width: 45%;
    height: 335px;
  }
}

/* add modal */
.item-image-div {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 130px;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  overflow: hidden;
}

.item-image-div i {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 5px 7px;
  border-radius: 50%;
  cursor: pointer;
}

.item-image-div i:hover {
  background: #f03a3a;
  color: #fff;
}

.edit-item-del-img-btn:hover {
  background: #f03a3a;
  color: #fff;
}

.item-image-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-input-div {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 130px;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
}

.item-img-upload-label {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  padding: 10px 58px;
  text-align: center;
  border-radius: 11px;
  cursor: pointer;
  background-color: #ededed;
  background-image: url("../images/image-upload-bg.png");
  background-position: 60px 6px;
  background-size: 75px !important;
  background-repeat: no-repeat;
}

.modal-body .add-item {
  background: #448aff;
  color: #fff;
  border: 0;
  padding: 6px 30px;
  border-radius: 3px;
  margin-top: 7px;
  font-weight: 500;
}

.modal-body .add-item:hover {
  background: #2976fa;
}

/* update photo */
.update-item-input-div {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 130px;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
}

.update-item-img-upload-label {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  padding: 50.5px 119px;
  vertical-align: middle;
  border-radius: 11px;
  cursor: pointer;
}

/* delivery info */
.react-tel-input .form-control {
  width: 100%;
}

.more-details {
  color: #929292;
  font-size: 13px;
  margin: -10px 0 5px 0;
}

.send-to-verify {
  float: right;
  margin-top: 7px;
  color: #fff;
  background: #ec7871;
  border: 0;
  padding: 3px 9px;
  padding: 3px 7px;
}

@media (max-width: 380px) {
  .open-hours-table {
    font-size: 10px;
  }
}

@media (min-width: 381px) and (max-width: 480px) {
  .open-hours-table {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .save-btn {
    font-size: 14px;
    margin-left: 0;
  }

  .back-btn {
    font-size: 14px;
  }
}

/* verification modal */
.styles_react-code-input__CRulA input:nth-child(1) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(2) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  border-right: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(3) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(4) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:focus {
  border: 2px solid #505050 !important;
}

/* accordion */
.category-accordion,
.menu-accordion,
#manageCatModal .modal-body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif; */
  color: #151b26;
}

.category-accordion .card-header .fa-caret-right {
  transition: 0.3s transform ease-in-out;
  transform: rotate(90deg);
  color: #000000;
  margin-right: 15px;
  margin-top: 0;
  margin-left: 10px;
  cursor: pointer;
}

.category-accordion .card-header .fa-caret-right:hover {
  color: blue;
}

.category-accordion .card-header .fa-caret-right[aria-expanded="false"] {
  transform: rotate(0deg);
  margin-right: 8px;
  margin-top: 3px;
}

.menu-accordion .card-header .fa-angle-right {
  transition: 0.3s transform ease-in-out;
  transform: rotate(90deg);
  color: #777777;
}

.menu-accordion .collapsed .fa-angle-right {
  transform: rotate(0deg);
}

.menu-accordion .menu-item:hover {
  background: #e1f0ff !important;
}

.category-accordion .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-accordion .card-title {
  margin-bottom: 0;
  width: 40%;
  display: flex;
}

.update-col span {
  display: flex;
  padding-top: 6px;
}

.update-col span .fa-pen {
  margin-right: 5px;
}

.menu-accordion .card-header {
  position: relative;
  padding-left: 10px !important;
}

.menu-accordion .fa-sort-up,
.menu-accordion .fa-sort-down {
  visibility: hidden;
}

.menu-accordion .fa-sort-up {
  position: absolute;
  top: 16px;
  margin-left: -10px;
}

.menu-accordion .fa-sort-down {
  position: absolute;
  bottom: 16px;
  margin-left: -10px;
}

.menu-accordion .card-header:hover .fa-sort-up,
.menu-accordion .card-header:hover .fa-sort-down {
  visibility: visible;
  color: rgb(158, 155, 155);
  font-size: 12px;
  cursor: pointer;
}

.manage-menu-main {
  width: 100%;
  margin: 0;
  background-color: #f0f8ff;
  border-radius: 5px 5px 0 0;
}

.category-accordion {
  background-color: #fafafa;
}

.category-accordion .card-body {
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
}

.menu-accordion .name-col {
  width: 43%;
  padding-left: 10px !important;
  align-items: center;
  cursor: default;
  display: flex;
}

.manage-menu-wrapper {
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 1rem;
  background-color: #ffffff;
}

.menu-accordion .card-block.collapse.show {
  border-bottom: 1px solid #dee2e6;
}

.menu-accordion .selling-price-col {
  width: 33%;
  text-align: right;
  color: #151b26;
  align-items: center;
}

.menu-accordion .inventory-col {
  width: 11%;
  text-align: center;
  color: #151b26;
  align-items: center;
}

.menu-accordion .image-col {
  width: 15%;
  text-align: center;
  color: #151b26;
  align-items: center;
}

.menu-accordion .availability-col {
  width: 9%;
  text-align: center;
  color: #151b26;
  align-items: center;
}

.menu-accordion .ingrediant-inventory-col {
  width: 20%;
  text-align: left;
  color: #151b26;
}

.menu-accordion .availability-col .custom-switch {
  float: unset;
}

.menu-accordion .custom-control-input:checked ~ .custom-control-label {
  margin-top: -2px;
}

.menu-accordion .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.menu-accordion .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.menu-accordion .custom-control-label::before {
  background-color: #e0e0e0;
}

.menu-accordion .custom-switch .custom-control-label::after {
  background-color: #ffffff;
}

@media (max-width: 575px) {
  .menu-accordion .name-col {
    width: 100%;
    font-weight: 600;
    margin-bottom: 5px;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
  }

  .menu-accordion .selling-price-col {
    width: 33%;
  }

  .menu-accordion .image-col {
    width: 33%;
  }

  .menu-accordion .availability-col {
    width: 33%;
    border-right: none;
  }

  .menu-accordion .update-col {
    width: 100%;
    border-top: 1px solid #dee2e6;
  }
}

@media (min-width: 576px) and (max-width: 900px) {
  .menu-accordion .name-col {
    width: 33%;
  }

  .menu-accordion .selling-price-col {
    width: 13%;
  }

  .menu-accordion .image-col {
    width: 18%;
  }

  .menu-accordion .availability-col {
    width: 18%;
  }

  .menu-accordion .update-col {
    width: 18%;
  }
}

/* update-image-div */
.item-name-wrapper h6 {
  margin-bottom: 1rem;
}

.item-name-wrapper {
  display: flex;
  justify-content: space-between;
}

.remove-item {
  cursor: pointer;
  color: #dc3545;
  font-size: 12px;
  width: 100px;
  text-align: right;
  text-decoration: underline;
  line-height: 27px;
}

.remove-item:hover {
  color: #bd0e1f;
}

.item-name-wrapper .item-name-edit-button {
  visibility: unset !important;
}

textarea {
  min-height: 80px;
}

/* .remove-item:hover {
  transform: scale(1.1);
} */

.edit-image {
  display: block;
  margin-bottom: 1rem;
}

.manage-menu-wrapper .update-image-div {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  text-align: center;
  background-color: #ededed;
}

.manage-menu-wrapper .update-image-div img {
  border-radius: 5px;
  height: 166px;
  object-fit: scale-down;
  max-width: 400px;
}

.manage-menu-wrapper .update-image-div .fa-plus {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: #000;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.manage-menu-wrapper .update-image-div .file-input {
  display: none;
}

.manage-menu-wrapper .updateDesc:focus {
  border: 1.5px solid #ff6961;
  outline: 0;
  box-shadow: none;
}

.manage-sidebar-menu-buttons {
  text-align: right;
  margin-bottom: 1rem;
}

.portion-list-wrapper .remove-item {
  margin-left: 2rem;
}

.manage-menu-wrapper .save-edit-btn {
  background: #448aff;
  color: #fff;
  border: 0;
  padding: 6px 30px;
  border-radius: 3px;
  margin-top: 7px;
  font-weight: 500;
}

.manage-menu-cancel {
  background: #d4d4d4;
  color: #4f4f4f;
  border: 0;
  padding: 6px 30px;
  border-radius: 3px;
  margin-top: 7px;
  margin-right: 1rem;
  font-weight: 500;
}

.manage-menu-wrapper .save-edit-btn:focus {
  outline: 0;
}

/* manage category modal */
#manageCatModal .modal-body .row:hover {
  background: rgb(243, 243, 243) !important;
  cursor: inherit !important;
}

#manageCatModal .modal-body .row .fa-sort-up,
#manageCatModal .modal-body .row .fa-sort-down {
  visibility: hidden;
  font-size: 14px;
}

#manageCatModal .modal-body .row .fa-sort-up {
  position: absolute;
  top: 8px;
  margin-left: -10px;
}

#manageCatModal .modal-body .row .fa-sort-down {
  position: absolute;
  bottom: 8px;
  margin-left: -10px;
}

#manageCatModal .modal-body .row:hover .fa-sort-up,
#manageCatModal .modal-body .row:hover .fa-sort-down {
  visibility: visible;
  color: rgb(158, 155, 155);
  font-size: 14px;
  cursor: pointer;
}

/* new design */
.add-address-btn {
  background: #ffffff;
  color: #ff7068;
  border: 0;
  padding: 7px 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 15%;
  border-radius: 5px;
}

.add-address-btn:hover {
  color: #e43026;
}

.add-address-btn:focus {
  outline: 0;
  box-shadow: none;
}

.add-phone-btn {
  display: block;
  background: #ffffff;
  color: #ff7068;
  border: 0;
  padding: 7px 0 0 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 15%;
  border-radius: 5px;
  text-align: left;
}

.add-phone-btn:hover {
  color: #e43026;
}

.add-phone-btn:focus {
  outline: 0;
  box-shadow: none;
}

.send-to-verify {
  background: #ff7068;
  color: #fff;
  width: 100%;
  border: 0;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 500;
}

.send-to-verify:focus {
  outline: 0;
  box-shadow: none;
}

.send-code-again {
  text-align: center;
  color: #ff7068;
  margin-top: 15px;
  margin-bottom: 0;
  cursor: pointer;
}

.cheeseburger-menu-outer {
  z-index: 2000 !important;
}

.cheeseburger-menu-overlay {
  z-index: 1500 !important;
}

.add-ons-wrapper {
  display: inline-block;
}

.add-ons-wrapper h6 span {
  color: #2979ff;
  font-size: 14px;
  cursor: pointer;
}

.add-ons-wrapper h6 span:hover {
  color: #005fff;
  text-decoration: underline;
}

.draggable-icon {
  margin-right: 10px;
  font-size: 12px;
  color: #777777;
  visibility: hidden;
  cursor: move;
}

.menu-item:hover .draggable-icon {
  visibility: visible;
}

.item-name-edit-button,
.category-name-edit-button,
.addon-price-edit-button {
  padding: 1px 7px;
  border-radius: 3px;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  font-weight: 600;
  color: #555;
  visibility: hidden;
}

.item-name-edit-button:hover,
.category-name-edit-button:hover {
  color: #2979ff;
}

.item-name-save-button,
.item-name-cancel-button {
  padding: 4px 7px;
  border-radius: 3px;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  font-weight: 600;
}

.item-name-save-button {
  color: #28a745;
}

.item-name-cancel-button {
  color: #dd3027;
}

.name-col:hover .item-name-edit-button {
  visibility: visible;
}

.card-header:hover .category-name-edit-button {
  visibility: visible;
}

.addons-details-title-wrapper:hover .category-name-edit-button {
  visibility: visible;
}

.addons-price-col:hover .addon-price-edit-button {
  visibility: visible;
}

.addons-details-title-wrapper .custom-control-label {
  cursor: pointer;
}

.addons-price-col .styles_Editext__main_container__2Y-IL{
  width: auto;
}

.item-name-edit-button:before,
.category-name-edit-button::before,
.addon-price-edit-button::before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f044";
}

.item-name-save-button:before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f00c";
}

.item-name-cancel-button:before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f00d";
}

.styles_Editext__main_container__2Y-IL {
  display: inline-block;
  flex-direction: column;
  width: calc(100% - 25px);
}

.portion-size-display .styles_Editext__main_container__2Y-IL {
  width: auto;
}

.card-title .styles_Editext__main_container__2Y-IL {
  display: inline-block;
  flex-direction: column;
  width: 95%;
}

.styles_Editext__input__1534X {
  padding: 5px 8px;
  border-radius: 3px;
}

.select-category-wrapper {
  display: flex;
}

.add-item-category-btn {
  margin-left: 1rem;
  padding: 5px;
  width: 100px;
  border: 2px solid #2979ff;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 13px;
  transition: 0.2s;
}

.add-item-category-btn:hover {
  border: 2px solid #2979ff;
  background-color: #2979ff;
  color: #ffffff;
}

.add-item-category-btn i {
  margin-right: 3px;
}

.add-portion-size-item-btn {
  height: 38px;
  width: 55px;
  border: 2px solid #2979ff;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 13px;
  display: block;
  transition: 0.2s;
}

.add-portion-size-item-btn:hover {
  border: 2px solid #2979ff;
  background-color: #2979ff;
  color: #ffffff;
}

#portion-price {
  padding-left: 35px;
}

.portion-size-wrapper {
  border: 1px solid #ededed;
  padding: 15px 15px 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.portion-size-wrapper .form-row {
  margin-right: 0;
  margin-left: 0;
  align-items: baseline;
}

.portion-size-col .price-unit {
  left: 13px;
}

.portion-list-wrapper {
  display: flex;
  margin-bottom: 0.5rem;
}

.portion-list {
  padding: 0 5px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}

.business-name-col.add-item-modal {
  width: 100%;
}

.page-inner-heading-sub {
  display: block;
  margin-bottom: 5px;
  margin-top: -9px;
  font-weight: 400;
  font-size: 11px;
  color: #4f4f4f;
  font-style: italic;
}

.sku-pos-wrapper {
  justify-content: space-between;
}

.add-image-text {
  margin-top: 80px;
  border: 1px solid #555555;
  padding: 3px;
  display: block;
  border-radius: 4px;
}
/* 
select.form-control {
  border-right: 7px solid #ededed;
} */

.price-col {
  position: relative;
}

#price {
  display: block;
  padding-left: 34px;
}

.price-unit {
  position: absolute;
  display: block;
  left: 9px;
  top: 41px;
  z-index: 9;
  font-size: 12px;
  color: #828282;
}

.add-item-buttons-wrapper {
  text-align: right;
  margin-top: 1rem;
}

.add-another-wrapper {
  text-align: right;
}

.add-another-wrapper label {
  margin-bottom: 0;
}

.add-another-check {
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;
}

.add-manage-item-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  align-items: center;
  margin-top: 1.5rem;
}

.manage-categories-inner {
  font-size: 13px;
  color: #2979ff;
  font-weight: 500;
  cursor: pointer;
}

.manage-categories-inner:hover {
  color: #005eff;
  text-decoration: underline;
}

.add-item-inner {
  cursor: pointer;
  font-size: 13px;
  padding: 5px 15px 7px;
  border: 1px solid #828282;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.2s;
}

.add-item-inner:hover {
  color: #ffffff;
  background-color: #828282;
  border: 1px solid #828282;
}

.add-category-wrapper {
  display: flex;
}

.add-cat-btn:focus,
.cancel-cat-btn:focus {
  outline: 0;
}

.menu-breadcrumb {
  font-size: 13px;
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
}

.go-back-menu-item {
  cursor: pointer;
}

.go-back-menu-item:hover {
  color: #2979ff;
  font-weight: 500;
  text-decoration: underline;
}

.add-ons-title h6 {
  margin-top: 1rem;
}

.add-ons-table-header-title {
  display: flex;
  width: 100%;
  background-color: #e3f2fd;
  border-radius: 5px 5px 0 0;
  padding: 6px 0;
}

.add-ons-table-main {
  width: 100%;
  border-bottom: 15px solid #ededed;
}

.add-ons-table-body-inner {
  display: flex;
  border-top: 1px solid #d4d4d4;
}

.add-ons-table-body-inner:hover .remove-addon {
  visibility: visible;
}

.add-ons-table-body-inner:hover {
  background-color: #f6f9ff;
}

.remove-addon-header {
  padding: 0px 22px;
  cursor: pointer;
  border-radius: 50%;
}

.addons-availability-col .custom-control-label {
  cursor: pointer;
}

.addons-details-col {
  width: 50%;
}

.addons-price-col {
  width: 18%;
  text-align: right;
}

.addons-availability-col {
  width: 10%;
  text-align: center;
}

.add-ons-details-wrapper {
  margin-top: 0.7rem;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #ffffff;
}

.addons-details-main-title {
  font-size: 15px;
}

.addons-details-title-wrapper {
  margin: 10px 0 15px 0;
  padding: 0 10px;
}

.add-ons-table-header-title .addons-details-col,
.add-ons-table-header-title .addons-price-col,
.add-ons-table-header-title .addons-availability-col {
  padding: 0 12px;
}

.add-ons-table-body-inner .addons-details-col,
.add-ons-table-body-inner .addons-price-col,
.add-ons-table-body-inner .addons-availability-col {
  padding: 8px 12px;
}

.remove-addon {
  padding: 8px 22px;
  cursor: pointer;
  /* visibility: hidden; */
  border-radius: 50%;
}

.remove-addon-group {
  padding: 8px 22px;
  cursor: pointer;
  border-radius: 50%;
}

.remove-addon:hover,
.remove-addon-group:hover {
  color: red;
}

.required-selection-wrapper {
  display: flex;
  margin-right: 2rem;
}

.required-selection-label,
.max-selections-wrapper label {
  margin-right: 10px;
}

.max-selections-wrapper select {
  padding: 4px 10px;
  border: none;
  background-color: #d4d4d4;
  border-radius: 4px;
  border-right: 4px solid #d4d4d4;
  vertical-align: middle;
}

.add-ons-settings {
  display: flex;
  margin-top: 0.7rem;
  align-items: center;
  justify-content: space-between;
}

.addons-details-col {
  padding-left: 2rem !important;
}

.add-addon,
.add-addon-group {
  padding: 10px 5px 10px 25px;
  color: #2979ff;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  border-top: 1px solid #d4d4d4;
}

.add-addon-group {
  border-top: 0;
  border-bottom: 1px solid #d4d4d4;
}

.add-addon:hover,
.add-addon-group:hover {
  text-decoration: underline;
  background-color: #f6f9ff;
}

.add-addon-group-wrapper {
  display: flex;
  padding: 10px;
  width: 45%;
}

.add-item-name-col {
  width: 50%;
  margin-right: 10px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  color: #222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__input:focus {
  border: 1.5px solid #ff6961;
  box-shadow: none;
  outline: 0;
  color: #495057;
  background-color: #fff;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 300px;
  border: 1px solid #efefef;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 6px 1px rgb(136 136 136);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid #d4d4d4;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.add-new-addon {
  font-weight: 500;
  font-size: 14px;
}

.react-autosuggest__suggestion-match {
  color: #1e88e5;
  font-weight: bold;
  font-size: 14px;
}

.add-addon-col {
  display: flex;
  padding: 8px 12px;
  padding-left: 1rem;
  width: 100%;
}

.add-addon-col .cancel-cat-btn {
  padding: 0 18px;
  margin-left: 0.5rem;
  height: calc(1.5em + 0.75rem + 6px);
  background: #d4d4d4;
  color: #4f4f4f;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}

.add-Item-input-wrapper {
  display: flex;
}

.add-addon-group-wrapper .add-cat-btn,
.add-addon-group-wrapper .cancel-cat-btn,
.add-Item-input-wrapper .add-cat-btn {
  height: calc(1.5em + 0.75rem + 7px);
  width: 75px;
}

.add-addon-group-input {
  width: 100%;
}

.add-ons-settings-inner {
  display: flex;
}

.addons-availability-col .custom-switch {
  float: unset;
}

.addons-availability-col .custom-control-input:checked ~ .custom-control-label {
  margin-top: -2px;
}

.draggable-icon-addon {
  font-size: 12px;
  padding: 11px 0 11px 15px;
  color: #777777;
  cursor: move;
}

textarea.form-control {
  height: 80px;
  max-height: 200px;
}

.spinner-border.spinner-border-sm.image-upload {
  font-size: 14px;
  position: absolute;
  top: 34%;
  left: 43%;
  width: 50px;
  height: 50px;
  border-width: 0.4em;
}

.show-all-addon-items .add-ons-table-header-title .addons-details-col {
  padding-left: 1.6rem !important;
}

.show-all-addon-items .addons-details-col {
  width: 93%;
}

.show-all-addon-items .remove-addon {
  padding: 8px 22px;
  cursor: pointer;
  visibility: visible;
  border-radius: 50%;
}

.all-addons-count {
  padding: 8px 0px 8px 20px;
}

.addons-details-title-wrapper .styles_Editext__view_container__2l2kB {
  font-size: 15px;
  font-weight: 500;
}

.addons-details-title-wrapper .styles_Editext__main_container__2Y-IL {
  width: 52.5%;
}

.sidebar-item-addon-title {
  font-size: 13px;
  font-weight: 500;
}

.sidebar-item-addon-info {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  padding-left: 1rem;
}

.sidebar-item-addon-display {
  margin-top: 0.3rem;
}

.update-image-div-inputs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-breadcrumb-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d4d4d4;
  margin-top: 0.5rem;
}

.menu-breadcrumb-bold {
  font-weight: 500;
}

.portion-size-display .addons-details-col:hover .item-name-edit-button {
  visibility: visible;
}

.portion-size-display .addons-price-col:hover .item-name-edit-button {
  visibility: visible;
}

.p-border {
  border-bottom: 1px solid #d4d4d4;
}

.portion-size-display .add-ons-details-wrapper {
  padding-bottom: 0.2rem;
}

.portion-size-display .add-ons-table-header-title .addons-details-col {
  padding-left: 2rem !important;
}

.portion-size-display .addons-price-col .styles_Editext__view_container__2l2kB {
  justify-content: flex-end;
}

.category-availability-wrapper {
  float: right;
  width: 9%;
  text-align: center;
}

.availability-col
  .custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #d4d4d4;
}

.category-collapse-area {
  width: 51%;
  height: 24px;
  cursor: pointer;
}

.category-collapse-area ~ .card-header {
  background: #976d6d;
}

.business-type-col select{
  cursor: pointer !important;
}