.settings-container {
  font-family: "Roboto", sans-serif;
  max-width: 900px;
  margin: auto;
  margin-top: 2.5rem;
  color: #333333;
  padding: 0 15px;
}

/* .breadcrumb */
.breadcrumb {
  background: transparent;
  padding-left: 0;
}

.breadcrumb .main-page {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #333333;
}

.breadcrumb .sub-page {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #333333;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

/* whole sec */
.sec {
  border-radius: 0.525rem;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

/* section header */
.sec-container {
  background: #e3f2fd;
  border-radius: 0.525rem 0.525rem 0px 0px;
  padding: 1.125rem;
}

.sec-title {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin-bottom: 0.5rem;
}

.sec-title-desc {
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.2rem;
  margin-bottom: 0;
}

/* sec content */
.content-container {
  background: #ffffff;
  border-radius: 0px 0px 0.525rem 0.525rem;
  padding: 0 1.125rem 1.125rem 1.125rem;
}

.setting-form .input-field {
  position: relative;
  width: 45%;
}

.setting-form .form-row {
  margin: 1.125rem 0 0 0;
}

.setting-form .title {
  font-size: 0.875rem;
  line-height: 1rem;
}

.setting-form .action {
  position: absolute;
  right: 0px;
  color: #2979ff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* line-height: 18px; */
  cursor: pointer;
}

.setting-form .inform {
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
}

.setting-form textarea {
  width: 100%;
}

.setting-form .input-field input,
.setting-form textarea,
.setting-form select {
  background: #ededed;
  border: 1px solid #ededed;
}
.setting-form select {
  cursor: pointer !important;
}

.setting-form .input-field input:focus,
.setting-form textarea:focus,
.setting-form select:focus {
  border: 1px solid #151b26 !important;
  background: #fff;
}

.setting-form .form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}

@media (max-width: 768px) {
  .setting-form .input-field {
    width: 100%;
  }
}

.setting-save-btn {
  background: #448aff;
  color: #fff;
  width: 100px;
  text-align: center;
}

.setting-save-btn:hover {
  color: #fff;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75);
}

.setting-save-btn:focus {
  box-shadow: none;
}

.setting-cancel-btn {
  background: #d4d4d4;
  color: #4f4f4f;
  width: 100px;
  margin-right: 10px;
  text-align: center;
}

.setting-cancel-btn:focus {
  box-shadow: none;
}

.setting-cancel-btn:hover {
  color: #4f4f4f;
  /* -webkit-box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(197, 197, 197, 0.75); */
}

/* form location */
.form-location .input-field {
  width: 90% !important;
  position: relative;
}

.form-location .input-field input {
  margin-bottom: 0.75rem !important;
}

.choose-map {
  width: 100%;
}

.choose-map .title {
  margin-top: 22px;
  font-size: 0.875rem;
  line-height: 1rem;
}

.choose-map .inform {
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
}

.address-dissable {
  background: #ededed;
  padding: 10px 15px;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .form-location .input-field {
    width: 100% !important;
  }
}

/* order settings */
.order-forms .input-field {
  width: 97.5%;
}

.col2 .order-forms .input-field {
  margin-left: 2.5%;
}

.input-prepends {
  background-color: #ededed;
  color: #828282;
  border: 0;
}

.enable-toggle {
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.enable-toggle .label {
  font-weight: 500;
  font-size: 18px;
}

.enable-toggle .toggle-btn::before {
  width: 32px;
  height: 20px;
  border-radius: 25px;
}

.enable-toggle .toggle-btn::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.25rem + 3px);
}

.custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none;
  border-color: #28a745;
  background-color: #28a745;
}

.custom-control-label::before {
  background-color: #e7e7e7;
  border: 0;
}

.custom-switch .custom-control-label::after {
  background: #fff;
}

.change-settings-btn {
  display: inline-block;
  float: right;
  font-size: 13px;
  color: #2979ff;
  cursor: pointer;
  margin-top: 4px;
}

.change-settings-btn.tax-comp {
  float: none;
}

/* add cost component */
.add-cost-com .title {
  font-size: 0.875rem;
  line-height: 1rem;
}

.add-cost-com select,
.add-cost-com textarea,
.add-cost-com input {
  background: #ededed;
  border: 1px solid #ededed;
}

.add-cost-com select:focus,
.add-cost-com textarea:focus,
.add-cost-com input:focus {
  border: 1px solid #000 !important;
}

.add-cost-com .payment-methods {
  width: 229px;
  margin-bottom: 10px;
}

.add-cost-com .inform {
  font-style: italic;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
}

.extra-cost-col1 {
  width: calc(100% - 50px);
  margin-bottom: 5px;
}

.extra-cost-col2 {
  width: 50px;
  padding-right: 2%;
  margin-bottom: 5px;
}

.extra-cost-col2 .com-del-btn {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

.extra-cost-col1 .title {
  font-size: 16px;
}

/* table QR */
.table-QR-col1 {
  width: calc(100% - 105px);
  margin-bottom: 5px;
}

.table-QR-col2 {
  width: 105px;
  padding-right: 2%;
  margin-bottom: 5px;
}

.table-QR-col2 .com-del-btn {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

.table-QR-col2 .qr-btn {
  font-size: 12px;
  line-height: 14px;
  color: #2979ff;
  float: right;
  margin-top: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.table-QR-col1 .title {
  font-size: 16px;
}

/* errors */
.invalid-feedback {
  display: block;
}

/* QR code */
* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}

.qr-code > div {
  max-width: 320px !important;
}

.qr-code {
  text-align: center;
}

.qr-code .name {
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  padding: 15px;
}

.qr-code .title {
  font-size: 24px;
  line-height: 28px;
  margin-top: 30px;
}

.qr-code img {
  border-radius: 5px;
}

.qr-code .sub-heading {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  padding: 0 15px;
}

.qr-code .table-name {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.qr-code .footer {
  padding: 10px;
}

.qr-code .footer .powered-by {
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #f2f2f2;
  margin-bottom: 0;
}

.qr-code .footer .spritzer {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ff6961;
  margin-bottom: 0;
}

.print-qr-btn {
  margin: 10px;
  border-radius: 5px;
  border: 0;
  background: rgba(242, 242, 242, 0.69);
  border-radius: 25px;
}

.print-qr-btn button {
  width: 70px;
  font-size: 15px !important;
  border-radius: 25px;
}

.print-qr-btn button:focus {
  box-shadow: none;
}

.print-qr-btn button:hover {
  background: #fff;
  border-radius: 25px;
}

.pay-at-counter-wrapper {
  display: flex;
  align-items: center;
  gap: 10rem;
  margin: 1rem 0 0.5rem;
}

.pay-at-counter-wrapper .title {
  font-size: 0.875rem;
  line-height: 1rem;
  min-width: 150px;
}

.pay-at-counter-wrapper .custom-control-label {
  cursor: pointer;
}

.one-input-item {
  margin-top: 1rem;
  max-width: 50%;
}

.integrations-wrapper .breadcrumb {
  padding: 0;
}

.integrations-wrapper .sec-container .sec-title {
  margin-bottom: 0;
}

.integrations-wrapper .add-ons-title {
  margin: 1.5rem 0 1rem;
}
