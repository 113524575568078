/* react-notifications-component */
.notification-container-top-right {
  top: 75px;
  right: 25px;
}

.notification-container-mobile-top {
  top: 75px;
  right: 15px;
  min-width: 300px;
  left: unset;
}

@media (max-width: 1100px) {
  .notification-container-top-right {
    right: 15px;
  }
}
