/* starts of our clients section */
.our-clients-sec .clients-heading-div {
  margin: 40px 0 20px 0;
}

.our-clients-sec .spritzer-logo {
  width: 150px;
  display: block;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 7px;
}

.our-clients-sec .sub-heading {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #4f4f4f;
}

.our-clients-sec:hover .see-customers {
  display: block;
}

.our-clients-sec .client-logo {
  width: 12%;
  margin: 2% 4%;
}

@media (max-width: 768px) {
  .our-clients-sec .client-logo {
    width: 17%;
    margin: 2% 4%;
  }

  .our-clients-sec img:nth-child(9),
  .our-clients-sec img:nth-child(10) {
    display: none;
  }
}
