/* navbar */
.copy-rest-link-div {
  display: inline-block;
  margin-right: 3rem;
  padding: 5px 21px 6px;
  background-color: #f2f2f2;
  border-radius: 20px;
  height: 34px;
}

.copy-rest-link {
  position: relative;
  background: transparent;
  border: 0;
  border-radius: 16px;
  color: #007bff;
  font-size: 14px;
  font-weight: 500;
}

.copy-rest-link:hover {
  text-decoration: underline;
}

.copy-rest-link:focus {
  outline: 0;
}

.copy-rest-link i {
  margin-left: 8px;
  color: #007bff;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

.nav-main-buttons-wrapper {
  display: flex;
  align-items: center;
}

.copy-res-link-span {
  margin-left: 10px;
  color: #007bff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .copy-rest-link-div {
    display: none;
  }
}

.dashboard-side-menu-col {
  position: relative;
  width: 14%;
  height: calc(100vh - (65px));
  overflow-y: auto;
  background-color: #f2f3f6;
  color: #333333;
  padding: 0 5px;
  border-radius: 3px;
  border-right: 1px solid #d4d4d4;
}

.dashboard-side-menu-col .hide-side-menu {
  margin-bottom: 20px;
}

.dashboard-side-menu-col .com-identy-div {
  display: flex;
  flex-direction: row;
  padding: 15px;
  min-height: 65px;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 10px;
}

.dashboard-side-menu-col .com-identy-div .brand-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.dashboard-side-menu-col .com-identy-div .brand-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.dashboard-side-menu-col .com-identy-div .shop-name {
  width: 160px;
  top: 16px;
  left: 62px;
  color: #333333;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.dashboard-side-menu-col .com-identy-div .location {
  top: 36px;
  left: 63px;
  color: #333333;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
}

.dashboard-side-menu-col .list-group-item {
  padding: 0.6rem 0.5rem 0.6rem 1rem;
  background-color: #f2f3f6;
  border: unset;
  font-size: 15px;
  border-radius: 3px;
}

.dashboard-side-menu-col a {
  color: #333333;
  text-decoration: none;
}

.dashboard-side-menu-col a i {
  width: 25px;
  text-align: center;
}

.dashboard-side-menu-col .list-group-item:hover {
  background-color: #e2e2e2;
  cursor: pointer;
}

.dashboard-side-menu-col .list-group-item a {
  color: #fff;
}

.dashboard-side-menu-col .list-group-item a {
  text-decoration: none;
}

#accordionSetting .card,
#accordionMenu .card {
  background-color: unset;
  border: 0;
  color: #fff;
  border-radius: 3px;
}

#accordionSetting .card .card-header,
#accordionMenu .card .card-header {
  font-size: 15px;
  padding: 0.6rem 0.5rem 0.6rem 1rem;
  color: #333333;
}

#accordionSetting .card .card-header.collapsed,
#accordionMenu .card .card-header.collapsed {
  background-color: #f2f3f6;
}

#accordionSetting .card .card-header .fa-angle-right,
#accordionMenu .card .card-header .fa-angle-right {
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
  position: relative;
  top: 3px;
  right: -5px;
}

#accordionSetting .card .card-header.collapsed .fa-angle-right,
#accordionMenu .card .card-header.collapsed .fa-angle-right {
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
  position: relative;
  top: 1px;
  right: -5px;
}

#accordionSetting .card .card-header.collapsed:hover,
#accordionMenu .card .card-header.collapsed:hover {
  background-color: #e2e2e2;
}

#accordionSetting .card .card-header i,
#accordionMenu .card .card-header i {
  width: 25px;
  text-align: center;
}

#accordionSetting .card .card-header .fa-angle-right,
#accordionMenu .card .card-header .fa-angle-right {
  padding-top: 5px;
}

.dashboard-side-menu-col .card .list-group-item {
  background-color: #ededed;
  padding: 0.6rem 0.5rem 0.6rem 3.15rem;
  font-size: 13px;
}

.dashboard-side-menu-col .card .list-group-item:hover {
  background-color: #e2e2e2;
  cursor: pointer;
}

.dashboard-content-col {
  position: absolute;
  right: 0;
  width: 86%;
  overflow-y: auto;
  height: calc(100vh - (65px));
  background-color: #f2f2f2;
}

.dashboard-side-menu-col .side-menu-hide {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  float: right;
  color: rgb(216, 216, 216);
  cursor: pointer;
  z-index: 1;
}

.user-display-btn {
  padding: 8px 15px;
  background-color: rgba(130, 130, 130, 0.41);
  border-radius: 5px;
}

.user-display-btn.dropdown-toggle::after {
  margin-left: 0.5em;
  vertical-align: 0.1em;
}

.user-display-btn span {
  font-weight: 500;
}

.dashboard-content-col .side-menu-show {
  position: fixed;
  top: 63px;
  padding: 10px;
  font-size: 25px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  z-index: 1021;
}

.dashboard-side-menu-col .side-menu-hide:hover {
  color: #2979ff;
}

.dashboard-content-col .side-menu-show:hover {
  color: rgb(53, 53, 53);
}

.dashboard-content-col .page-section-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 7px 15px;
}

.delivery-on-off-row {
  position: fixed;
  bottom: 0px;
  left: 10px;
}

.nav-main-buttons {
  display: flex;
  align-items: center;
}

.spritzerapp-link {
  margin-right: 3rem;
  padding: 4px 21px 6px;
  background-color: #f2f2f2;
  border-radius: 20px;
}

.spritzerapp-link i {
  font-size: 14px;
  margin-left: 8px;
  color: #2979ff;
}

.res-availability-buttons button:first-child {
  border-radius: 0 4px 4px 0;
}

.res-availability-buttons button:nth-child(2) {
  border-radius: 4px 0 0 4px;
}

@media (max-width: 575px) {
  .delivery-on-off-row {
    position: relative;
    bottom: 0px;
    left: 25px;
    right: 10px;
    max-width: 300px;
  }

  .dashboard-side-menu-col .side-menu-hide {
    top: 78px;
    right: 12px;
  }
}

.delivery-on {
  background: #28a745;
  padding: 2px 7px;
  margin: 10px 0;
  float: right;
  border: 2px solid;
  color: #fff;
  border: 2px solid #28a745;
  font-size: 14px;
  width: 50%;
}

.delivery-off {
  background-color: #dc3545;
  padding: 2px 7px;
  margin: 10px 0;
  float: right;
  color: #fff;
  border: 2px solid #dc3545;
  font-size: 14px;
  width: 50%;
}

.delivery-disable {
  background: #e6e6e6;
  color: #6f6f6f;
  padding: 2px 7px;
  margin: 10px 0 10px 0px;
  float: right;
  border: 2px solid #e6e6e6;
  border-left: none;
  font-size: 14px;
  width: 50%;
}

.delivery-disable:hover {
  background: #cccccc;
  color: #353535;
  border: 2px solid #ccc;
  border-left: 0;
}

.delivery-disable:focus {
  outline: 0;
  box-shadow: none;
}

.delivery-on:focus,
.delivery-off:focus {
  outline: 0;
  box-shadow: none;
}

@media (max-width: 574px) {
  .dashboard-side-menu-col {
    width: 100%;
    position: unset;
  }

  .dashboard-content-col {
    width: 100%;
    position: unset;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .dashboard-side-menu-col {
    width: 40%;
  }

  .dashboard-content-col {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dashboard-side-menu-col {
    width: 30%;
  }

  .dashboard-content-col {
    width: 70%;
  }
}

@media (min-width: 992px) and (max-width: 1224px) {
  .dashboard-side-menu-col {
    width: 22%;
  }

  .dashboard-content-col {
    width: 78%;
  }
}

@media (min-width: 1225px) and (max-width: 1365px) {
  .dashboard-side-menu-col {
    width: 20%;
  }

  .dashboard-content-col {
    width: 80%;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .dashboard-side-menu-col {
    width: 17%;
  }

  .dashboard-content-col {
    width: 83%;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .dashboard-side-menu-col {
    width: 15%;
  }

  .dashboard-content-col {
    width: 85%;
  }
}

.dashboard-app-div {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 15px;
}

.dashboard-app-div a {
  width: 100px;
  display: block;
  margin: auto;
}

.dashboard-app-div img {
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* pre loader */
.sc-AxjAm {
  position: unset !important;
  width: unset !important;
  height: calc(100vh - 60px) !important;
}

.store-links a:first-child {
  margin-bottom: 0.5rem;
}
