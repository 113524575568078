.day-stats {
  color: #000;
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 6px;
  width: 95%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.sales-dispaly-block {
  margin-bottom: 2rem;
}

.status-border-blue {
  border-left: 7px solid #2f80ed;
}

.status-border-green {
  border-left: 7px solid #219653;
}

.status-border-grey {
  border-left: 7px solid #828282;
}

.status-border-orange {
  border-left: 7px solid #ff9839;
}

.day-stats p,
.day-stats h3 {
  margin-bottom: 0;
}

.day-stats p {
  color: #696969;
  font-size: 14px;
}

.day-stats h3 {
  font-size: 20px;
  margin-bottom: 3px;
}

.day-stats-title {
  display: flex;
  align-items: center;
}

.day-stats-title svg {
  margin-left: 5px;
  width: 19px;
  height: auto;
  margin-top: -4px;
}

.sales-dispaly-wrapper {
  padding-top: 2rem;
}

.order-menu-header .orderid-col {
  width: 10%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .date-col {
  width: 20%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .customer-col {
  width: 14%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .suburb-col {
  width: 14%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .channel-col {
  width: 14%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .amount-col {
  width: 14%;
  display: flex;
  align-items: center;
  text-align: right;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.order-menu-header .pay-method-col {
  width: 14%;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.manage-orders-main-wrapper {
  padding-left: 2%;
  padding-right: 2%;
  background-color: #F2F2F2;
  /* background-color: #fafafa; */
  margin-bottom: -1.5rem;
  padding-bottom: 2rem;
}

.select-order-type-main-wrapper .tab-content > .active {
  padding-top: 1.5rem;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}

.row .order-menu-header {
  padding: 10px 0;
  background-color: #333333;
  color: #FFFFFF;
  /* background-color: #ededed; */
  border-radius: 6px 6px 0 0;
}

.select-order-type-main-wrapper .nav-pills {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.select-order-type-main-wrapper .nav-item {
  width: 20%;
  background-color: #ffffff;
  border-right: 2px solid #d8d8d8;
}

.select-order-type-main-wrapper .nav-item:hover {
  background-color: #f7f7f7;
}

.select-order-type-main-wrapper .nav-item:hover .nav-link {
  background-color: #f7f7f7;
}

.select-order-type-main-wrapper .nav-item:first-child {
  border-radius: 6px 0 0 6px;
}

.select-order-type-main-wrapper .nav-item:last-child {
  border-right: none;
  border-radius: 0 6px 6px 0;
}

.select-order-type-main-wrapper .nav-pills .nav-link {
  padding: 1.1rem 1rem 0.85rem 1rem;
  color: #828282;
  background-color: #ffffff;
  border-radius: inherit;
  font-size: 15px;
  text-align: center;
}

.select-order-type-main-wrapper .nav-pills .nav-link.active {
  color: #448aff;
  background-color: #ffffff;
  border-bottom: 6px solid #448aff;
  font-weight: 500;
}

.order-accordion .card-header .more-col .fa-angle-right {
  transition: 0.3s transform ease-in-out;
  transform: rotate(90deg);
  color: #777777;
  margin-top: 5px;
}

.order-accordion .card-header .collapsed .fa-angle-right {
  transform: rotate(0deg);
  float: right !important;
}

.order-section-main {
  background: #ffffff;
}

.order-sec-heading {
  color: #2f2f2f;
  font-weight: 500;
  font-size: 18px;
  background: #ffffff;
  padding: 35px 15px 10px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid #d4d4d4;
}

.order-accordion {
  min-width: 900px;
}

.new-orders {
  background-image: linear-gradient(-360deg, #309bea, rgb(0, 235, 252));
  position: fixed;
  top: 110px;
  left: 58%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  padding: 8px 25px;
  border-radius: 25px;
  font-weight: 600;
  box-shadow: 2px 2px 4px #000;
  cursor: pointer;
  z-index: 1111;
  border: none;
}

.new-orders:focus {
  outline: 0;
  box-shadow: none;
}

.order-accordion .card {
  background: #fff;
  color: #151b26 !important;
  border-left: unset;
  border-right: unset;
}

.order-accordion .card-header {
  background: #fff;
  color: #151b26 !important;
}

.order-info-main-wrapper:hover {
  background: rgb(245, 245, 245);
}

.order-accordion .card-header .paid {
  display: inline-block;
  background: #20aaea;
  color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 11px;
}

.order-accordion .card-header .cod {
  display: inline-block;
  background: #fd9a00;
  color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 11px;
}

.order-info-main-wrapper .scheduled-order-icon {
  margin-left: 15px;
  position: relative;
}

.order-accordion .card-header .accept,
.order-accordion .card-header .ready {
  font-size: 13px;
  width: 70px;
}

.order-accordion .card-header .reject {
  font-weight: 500;
  padding: 1px 8px 1px 8px;
  margin-left: 5px;
  font-size: 13px;
}

.order-accordion .card-header .reject:hover {
  color: #b3091a !important;
}

.order-accordion .card-header .delivered {
  display: inline-block;
  background: #3bca5c;
  color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 11px;
  margin-top: 4px;
}

.order-accordion .card-header .rejected {
  background: #ec1d1d;
  color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 11px;
  display: inline-block;
  margin-top: 4px;
}

.order-accordion .card-header .fa-angle-right {
  color: #777777;
}

.card-header .accept:focus,
.card-header .reject:focus {
  outline: 0;
  box-shadow: none;
}

.accordion .card-body {
  font-size: 14px;
}

.additional-delivery-info-div .page-inner-heading {
  font-size: 13px;
}

.additional-delivery-info-div .additional-info-input {
  width: 85%;
  display: inline-block;
  font-size: 13px;
}

.additional-delivery-info-div .additional-info-input:focus {
  border: 2px solid #ff6961;
  outline: 0;
  box-shadow: none;
}

.additional-delivery-info-div .info-save-btn {
  background: rgb(255, 255, 255);
  border: 0px;
  color: rgb(255, 105, 97);
  width: 15%;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}

.additional-delivery-info-div .info-save-btn:hover {
  color: rgb(240, 54, 45);
}

.additional-delivery-info-div .info-save-btn:focus {
  outline: 0;
}

.additional-delivery-info-div .info-edit-btn {
  background: rgb(255, 255, 255);
  border: 0px;
  color: rgb(255, 105, 97);
  width: 15%;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}

.map-col > div {
  width: 90% !important;
}

.order-info-main-wrapper {
  padding: 10px 0px;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  align-items: center;
}

.order-info-main-wrapper .orderid-col {
  width: 10%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .date-col {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .customer-col {
  width: 14%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .channel-col {
  width: 14%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .suburb-col {
  width: 14%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .amount-col {
  width: 14%;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.order-info-main-wrapper .pay-method-col {
  width: 14%;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.dinein-label {
  display: inline-block;
  min-width: 86px;
  color: #142929;
  padding: 3px 15px;
  text-align: center;
  background: rgba(45, 89, 91, 0.14);
  border-radius: 5px;
}

.delivery-label {
  display: inline-block;
  min-width: 86px;
  color: #502a08;
  padding: 3px 15px;
  text-align: center;
  background: rgba(240, 115, 0, 0.14);
  border-radius: 5px;
}

.pickup-label {
  display: inline-block;
  min-width: 86px;
  color: #582c81;
  padding: 3px 15px;
  text-align: center;
  background: rgba(155, 81, 224, 0.14);
  border-radius: 5px;
}

.cash-label {
  display: inline-block;
  min-width: 86px;
  color: #333333;
  padding: 3px 15px;
  text-align: center;
  background: rgba(79, 79, 79, 0.2);
  border-radius: 5px;
}

.card-label {
  display: inline-block;
  min-width: 86px;
  color: #0b331c;
  padding: 3px 15px;
  text-align: center;
  background: rgba(33, 150, 83, 0.14);
  border-radius: 5px;
}

.reddot-icon{
  color: #C03939;
  font-size: 0.4rem;
  margin-left: 20px;
}

.order-times {
  display: flex;
  justify-content: space-between;
}

.reqested-time {
  text-align: right;
}

.additional-order-info-heading,
.reqested-time h6,
.ordered-time h6,
.order-contact-details h6,
.order-delivery-note h6,
.order-dine-in-table h6,
.order-address-title {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0.2rem;
  color: #555555;
}

.order-address-sec,
.reqested-time p,
.ordered-time p,
.order-contact-details p,
.order-dine-in-table p,
.order-delivery-note p {
  font-weight: 500;
  font-size: 16px;
}

.action-col {
  padding: 0 2rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.action-col .btn {
  width: 48%;
  font-weight: 500;
}

.action-col .btn:nth-child(2) {
  margin-left: 15px;
}

.action-col .form-check {
  padding-left: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.action-col .form-check .form-check-label {
  font-size: 1rem;
}

.action-col .form-check .form-check-input {
  position: absolute;
  margin-left: -1.5rem;
  width: 1em;
  height: 1em;
  margin-top: 0.18em;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.order-address-info {
  display: flex;
  margin-bottom: 3rem;
}

.order-address-info .order-address-block {
  min-width: 23%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.oai-header {
  display: flex;
  justify-content: space-between;
}

.oai-header .btn-light {
  padding: 0.1rem 0.9rem;
  background-color: transparent;
  border-color: transparent;
}

.info-save-btn-block {
  text-align: right;
  margin-top: 7px;
}

.additional-info-input {
  max-height: 150px;
}

.order-additional-info {
  margin-bottom: 2rem;
}

.info-save-btn .spinner-border {
  margin-right: 5px;
  vertical-align: middle;
}

.order-map-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f2f2f2;
}

.order-map-block .text-danger {
  margin-bottom: 0;
}

.order-status-display {
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 30px;
}

.order-status-display.delivered {
  background-color: #26bf02;
}

.order-status-display.rejected {
  background-color: #ff6347;
}

.scheduled-order-tooltip {
  display: none;
}

.scheduled-order-icon:hover .scheduled-order-tooltip {
  display: block;
  position: absolute;
  top: -59px;
  right: -60px;
  background-color: #28202b;
  padding: 6px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
  color: #ffffff;
  width: 100px;
}

.scheduled-order-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 32%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.order-more-info-close {
  border-radius: 50%;
  color: #535353;
  background-color: #ffffff;
  opacity: 1;
  z-index: 1000;
}

.order-more-info-close .close-cart {
  vertical-align: middle;
}

.order-info-display > .modal-header,
.order-info-display > .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.day-stats.status-border-blue,
.day-stats.status-border-grey {
  width: 100%;
}

.sd-padding {
  padding-left: 2.1%;
}

@media (max-width: 1500px) {
  .day-stats {
    width: 100%;
  }
  .sd-padding {
    padding-left: 2%;
  }
}

@media (max-width: 1445px) {
  .sd-padding {
    padding-left: 15px;
  }
}

@media (max-width: 1375px) {
  .day-stats {
    padding: 1rem 1rem;
  }
}

@media (max-width: 1265px) {
  .day-stats {
    padding: 1rem 1rem;
  }
}

@media (max-width: 1200px) {
  .select-order-type-main-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: 575px) {
  .map-col {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 15px;
  }

  .map-col > div {
    width: 90% !important;
    height: 200px !important;
  }

  .additional-delivery-info-div .additional-info-input {
    width: 100%;
    display: inline-block;
  }

  .additional-delivery-info-div .info-save-btn {
    margin-top: 10px;
    padding-left: 0;
  }
}

.edit-item-del-img-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 5px 7px;
  border-radius: 50%;
  cursor: pointer;
}
