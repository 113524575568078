* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* starts of sign up styles */
.company-logo {
  width: 150px;
}

.business-app-navbar {
  background: #212121;
  color: #fff !important;
  height: 65px;
  font-family: "Roboto", sans-serif;
}

.business-app-navbar .nav-item {
  color: #fff !important;
}

.business-app-navbar .nav-item:hover {
  color: #ff6961 !important;
  text-decoration: none;
}

.business-app-navbar .sign-up-btn {
  background: #ff6961;
  color: #fff !important;
  font-weight: 700;
  padding: 10px 10px !important;
  border-radius: 4px;
  margin-left: 15px;
}

.business-app-navbar .sign-up-btn:hover {
  background: #d44747;
  color: #fff !important;
  text-decoration: none;
}

.sign-up-main-div {
  background: #f2f2f2;
  padding-bottom: 40px;
  min-height: calc(100vh - 65px);
}

.sign-up-form-div {
  margin: auto;
  max-width: 466px;
}

.get-started-heading {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #4f4f4f;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 575px) {
  .business-app-navbar .container {
    padding-right: 10px;
  }

  .business-address-badge {
    margin-top: 0;
    margin-bottom: 7px;
  }

  .business-app-navbar .sign-up-btn {
    padding: 7px 7px !important;
  }
}

/* ends of sign up styles */

/* starts of login styles */
.login-first-col {
  float: left;
  position: relative;
}

.login-second-col {
  float: left;
  width: 50vw;
  font-family: Helvetica, Arial !important;
}

.spritzer-bus-app-img-div {
  border-bottom: 100vh solid #ff6961;
  border-right: 36vw solid transparent;
  height: 0;
  width: 48vw;
}

.spritzer-bus-app-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.login-div {
  position: relative;
  width: 350px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-div .company-logo {
  margin: unset !important;
}

.login-page-heading {
  font-size: 32px;
  line-height: 48px;
  margin: 16px 0;
  letter-spacing: 0em;
  font-weight: 700;
}

.login-desc {
  font-size: 14px;
  font-family: plex-sans, Helvetica Neue, Helvetica, Arial;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 16px;
}

.login-form-label {
  font-size: 15px;
  font-family: plex-sans, Helvetica Neue, Helvetica, Arial;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 700;
}

.login-btn {
  width: 100%;
  background: #ff6961;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border: 0;
  margin-top: 15px;
}

.login-btn:hover {
  background: #d44747;
}

.login-btn:focus {
  outline: 0;
}

.login-btn:focus {
  outline: 0;
}

.forget-pwd-link:hover {
  text-decoration: underline;
}

.forget-pwd-btn {
  float: right;
  font-size: 18px;
  background: #ff6961;
  color: #fff;
  padding: 5px 20px;
  border-radius: 3px;
  border: 0;
}

.user-display-dropdown {
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.user-display-dropdown .user-name {
  width: 100%;
  display: block;
  font-size: 16px;
  text-align: center;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
  cursor: default;
}

.user-display-dropdown .user-email {
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.3rem;
  cursor: default;
}

.user-display-dropdown .user-active-state {
  color: #2979ff;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.7rem;
  cursor: default;
}

.user-display-dropdown .user-email i {
  margin-left: 5px;
  font-size: 12px;
  color: #2979ff;
}

.logout-link {
  border-top: 1px solid #d4d4d4;
}

.user-display-btn .dropdown-toggle::after {
  margin-left: 0.6em;
  vertical-align: 0;
}

@media (max-width: 480px) {
  .login-div {
    width: 90%;
  }
}

@media (max-width: 880px) {
  .login-div .company-logo {
    margin-top: 15px !important;
  }

  .login-first-col {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: auto;
  }

  .spritzer-bus-app-img-div {
    border-bottom: 60vh solid #ff6961;
    border-right: 100vw solid transparent;
  }

  .login-second-col {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100vw;
    height: auto;
  }
}

/* ends of login styles */

/* starts of customize-container  */
.customize-container {
  width: 65%;
  margin: auto;
}

@media (max-width: 680px) {
  .customize-container {
    width: 100%;
  }
}

@media (min-width: 681px) and (max-width: 767px) {
  .customize-container {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .customize-container {
    width: 90%;
  }
}

@media (min-width: 993px) and (max-width: 1224px) {
  .customize-container {
    width: 90%;
  }
}

@media (min-width: 1225px) and (max-width: 1439px) {
  .customize-container {
    width: 85%;
  }
}

@media (min-width: 1440px) and (max-width: 1600px) {
  .customize-container {
    width: 80%;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .customize-container {
    width: 70%;
  }
}

/* ends of customize-container  */

@media (max-width: 365px) {
  .company-logo {
    width: 86px !important;
  }
}

@media (max-width: 680px) {
  .company-logo {
    display: block;
    width: 130px;
    margin: auto;
    margin-top: 5px;
    margin-left: 5px;
  }
}

/* ends of side menu bar */

/* ends of manage listing styles */
.billing-dropdown {
  display: block;
  margin: auto;
  background: #fff;
  border-radius: 30px;
  border: 2px solid #878787;
  padding: 5px 15px;
}

.billing-dropdown:focus {
  outline: 0;
}

.billing-amount {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 26px;
  margin: 30px 0 5px 0;
  color: #2196f3;
}

.time-period {
  text-align: center;
  line-height: 21px;
  margin-bottom: 20px;
  font-size: 18px;
}

.bill-summery-row {
  width: 90%;
  margin: auto;
  text-align: center;
}

.bill-summery-row .completed-orders {
  color: #2196f3;
}

.bill-summery-row .big-font {
  font-size: 25px;
}

.bill-summery-row .first-p {
  font-weight: 600;
  font-size: 17px;
}

.bill-summery-row .second-p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.bill-summery-row .first-col {
  border-right: 2px solid #bfbfbf;
}

.bill-summery-row .second-col {
  border-right: 2px solid #bfbfbf;
}

.billing-table .table {
  width: 90%;
  margin: auto;
}

.analized-table .table {
  width: 90%;
  margin: auto;
}

.analized-table .table tr td {
  min-width: 102px;
}

@media (max-width: 575px) {
  .billing-table .table {
    width: 100%;
  }

  .billing-table .table .first-td {
    width: 60% !important;
  }
}

.billing-table-heading-bold {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

@media (min-width: 575px) and (max-width: 768px) {
  .bill-summery-row .second-col {
    border: 0;
  }

  .bill-summery-row .third-col {
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .bill-summery-row .first-col,
  .bill-summery-row .second-col {
    border: 0;
  }

  .bill-summery-row .second-col,
  .bill-summery-row .third-col {
    margin-top: 15px;
  }
}

/* ends of billing styles */

.form-group input:focus,
.item-input-col input:focus,
.price-input-col input:focus,
.form-group textarea:focus,
.form-row .form-control:focus {
  border: 1px solid #151b26;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.form-group input:hover,
.item-input-col input:hover,
.price-input-col input:hover,
.form-group textarea:hover,
.form-row .form-control:hover {
  cursor: text;
}

select:focus,
.form-group select:focus,
.vouchers-dropdown:focus,
.forget-email-input:focus {
  border: 1px solid #151b26;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

select:hover,
.form-group select:hover,
.vouchers-dropdown:hover,
.forget-email-input:hover {
  cursor: pointer;
}

.form-control.is-invalid {
  background-image: none;
}

.invalid-feedback {
  font-size: 80%;
}

.image-input-div {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
}

.imgUpload {
  display: none;
}

.img-upload-label {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  padding: 33.5px 36px;
  vertical-align: middle;
  border-radius: 11px;
}

.img-upload-label:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.manage-order-details-header {
  display: flex;
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 0;
}

.mod-item {
  width: 65%;
  padding: 4px 7px;
}

.mod-qty {
  width: 10%;
  text-align: center;
  padding: 4px 7px;
}

.mod-item-title {
  font-weight: 500;
}

.mod-amount {
  width: 25%;
  text-align: right;
  padding: 4px 7px;
}

.mo-order-menu-item {
  display: flex;
}

.mo-order-menu-item-addon {
  display: flex;
  color: #333333;
}

.mo-order-menu-item-addon-main {
  margin-bottom: 1.5rem;
}

.order-category-title {
  margin-bottom: 0.6rem;
}

.order-other-info {
  padding: 8px 10px 10px 11%;
}

.manage-order-details-total-wrapper,
.mod-total-min-order-wrapper,
.mod-sub-total-wrapper,
.mod-delivery-fee-wrapper,
.mod-grand-total-wrapper {
  display: flex;
  padding: 7px 7px;
  border-top: 1px solid #ffffff;
  justify-content: flex-end;
}

.manage-order-details-total-wrapper,
.mod-total-min-order-wrapper,
.mod-sub-total-wrapper,
.mod-delivery-fee-wrapper,
.mod-extra-cost-wrapper {
  background-color: #f2f2f2;
}

.order-info-display {
  width: 85%;
  margin: auto;
}

.manage-order-details-body {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mod-grand-total-wrapper {
  background-color: #ededed;
  font-weight: 500;
}

.mod-total-amount {
  width: 30%;
  text-align: right;
}

.manage-order-details-total-wrapper .mod-total-title {
  padding: 0 4px;
}

.mod-extra-cost-wrapper {
  padding: 7px 7px;
  border-top: 1px solid #ffffff;
  justify-content: flex-end;
}

.mod-extra-cost-wrapper .mod-total-title {
  text-align: right;
  padding-right: 30%;
}

.mod-extra-cost-inner {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  text-align: right;
}

input[type="number"] {
  text-align: right;
}
