/* @media (min-width: 576px) and (max-width: 992px) { */
.to-do-container {
  margin-bottom: 150px !important;
}
/* }

@media (max-width: 575px) {
  .to-do-container {
    margin-bottom: 175px !important;
  }
} */

.to-do-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin: 0 8px;
}

.to-do-desc {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 8px 8px 20px 8px;
}

.to-do-div {
  display: inline-block;
  width: 31%;
  margin: 1.1%;
  padding: 15px;
  border-radius: 8px;
}

.to-do1 {
  background: #9b51e0;
}
.to-do2 {
  background: #2d9cdb;
}
.to-do3 {
  background: #283593;
}
.to-do4 {
  background: #da552c;
}
.to-do5 {
  background: #219653;
}

.to-do-div:hover {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.to-do-div img {
  display: block;
  margin: auto;
  margin-bottom: 25px;
}

.to-do-div .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 5px;
  cursor: default;
}

.to-do-div .small-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  height: 30px;
  cursor: default;
}

.to-do-div a {
  display: block;
  width: fit-content;
  background: #f2f2f2;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px 15px;
  margin-top: 25px;
}

.to-do-div a:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}

.to-do-div .completed-to-do {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 0;
  margin-top: 27px;
  margin-bottom: 0;
}

@media (min-width: 481px) and (max-width: 992px) {
  .to-do-div {
    width: 46%;
    margin: 2%;
  }

  .to-do-div .title {
    font-size: 16px;
  }

  .to-do-div .small-desc {
    font-size: 13px;
    height: 40px;
  }

  .to-do-div a {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .to-do-div {
    width: 95%;
    margin: 2%;
  }
}

/* select a plan */
.to-do-select-plan {
  width: 86%;
  position: fixed;
  bottom: 0;
}

@media (max-width: 574px) {
  .to-do-select-plan {
    width: 100%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .to-do-select-plan {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .to-do-select-plan {
    width: 70%;
  }
}

@media (min-width: 992px) and (max-width: 1224px) {
  .to-do-select-plan {
    width: 78%;
  }
}

@media (min-width: 1225px) and (max-width: 1365px) {
  .to-do-select-plan {
    width: 80%;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .to-do-select-plan {
    width: 83%;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .to-do-select-plan {
    width: 85%;
  }
}
